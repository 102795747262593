<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.center{text-align: center;}
.right{text-align: right;}

.blocked td{background-color:#eee !important;}

.tips{margin-top:10px; font-size:12px; color:#981515;}
.el-cascader{width:170px;}
.el-input__inner{width:100%;}
.table-box{padding: 10px; background: #fff;}
.step{border-radius: 3px; background: #ecf5ff; box-shadow: 1px 1px 5px rgba(0,0,0,0.5); min-width: 1150px; max-width: 1300px;}
.step:nth-of-type(n+2){margin-top:30px;}
.step-head{padding: 15px 20px; background:#c6e2ff;}
.step-name{font-size: 22px; color: #333; text-shadow: 1px 1px 3px #999}
.step-intro{font-size:13px; color: #740e22; padding-left: 10px; text-shadow: 1px 1px #eee}
.step-body{padding: 30px 20px;}
.oprate-box{display: flex; align-items: center;}
.people-box{display: flex; align-items: center; padding-left: 10px;}
.count-1{font-size:26px; color:#740e22; font-weight: bold;}
.count-2{font-size:26px; color:#c80000; font-weight: bold;}
.sms .column{display:flex !important; flex-direction: column; align-items: stretch; justify-content: space-between;}
.sms .column .title{flex:0 0 auto; background:#8cc5ff; padding:0 15px; height:41px; color:#333a9e; display: flex; align-items: center;}
.column .el-textarea{flex:1 1 auto; margin-top:-1px;}
.el-textarea__inner{ font-family: '微软雅黑'; text-align: justify; word-break: break-all;}
.edding-box{flex:1 1 auto; margin-top:-1px; border-radius: 3px; background:#fff; border:solid 1px #dcdfe6; color:#666; padding:5px; font-size:14px; line-height: 150%; text-align: justify; word-break: break-all;}
.cursor{cursor:pointer;}

.flex-grow{flex:1 1 auto; text-align: left;}
.top{align-items: flex-start;}
.bottom{align-self: flex-end;}

</style>
<template>
    <div class="component sms">
	    <div class="title-panel">
	        <el-row>
	          <el-col :span="18">
	            <i class="el-icon-mobile-phone" style="color:#999; font-size:36px; margin-right:5px;"></i>
	            <div class="inline"><div class="label">群发短信</div><div class="descript">对权限区域内的用户群发短信，进行问卷、投票、选举等活动通知。</div></div>
	          </el-col>
	          <el-col :span="6" class="btn-col">
	            <!-- <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加选举</el-button> -->
	            <el-button type="primary" plain icon="el-icon-refresh" @click="reset">刷新数据</el-button>
	          </el-col>
	        </el-row>
	    </div>

	    <!-- 主体表格 -->
	    <div class="table-box">
	    	<div class="step">
	    		<div class="step-head">
	    			<span class="step-name">第一步：选择接收范围</span>
	    			<span class="step-intro">可以在权限范围内，按从巨到细进行选择，街道、社区、小区、栋、单元、层、户、个人等，选择最终到哪一级，则该级包含的人员都会收到短信。</span>
	    		</div>
	    		<div class="step-body">
	    			<div class="oprate-box">
	    				<template v-if="gd.me.role>2">
	    					<el-cascader style="width:180px;" v-model="area" :props="cascader_config" size="medium" clearable ref="area1" placeholder="选择行政区划" @change="this.$refs.area1.popperVisible = false;"></el-cascader>
	    					<div>&nbsp;</div>
	    				</template>
					    <el-select style="width:150px;" v-model="estate" size="medium" placeholder="选择小区" clearable>
			                <el-option v-for="(item,idx) in estates" :key="idx" :label="item.name" :value="item.id"></el-option>
			            </el-select>
			            <div>&nbsp;</div>
			            <template v-if="estate">
			            	<!-- <BufhCascader style="width:250px" :setting="bufh_setting" size="medium" @change="bufh_change"></BufhCascader> -->
			            	<el-cascader style="width:250px" v-model="bufh" :props="house_config" size="medium" placeholder="筛选户号" clearable @change="this.$refs.bufh.popperVisible=false" ref="bufh"></el-cascader>
			            	<div>&nbsp;</div>
			            </template>
			            <template v-if="estate && residents.length > 0">
			            	<el-select style="width:150px;" v-model="resident" size="medium" placeholder="选择人员" multiple collapse-tags clearable>
				                <el-option v-for="(item,idx) in residents" :key="idx" :label="item.truename" :value="item.id"></el-option>
				            </el-select>
				            <div>&nbsp;</div>
			            </template>
			            
			            <div class="people-box">
			            	<span>共</span>
				            <span class="count-2" v-html="resident_count"></span>
				            <span>人</span>
			            </div>
	    			</div>
		    	</div>
	    	</div>

	    	<div class="step">
	    		<div class="step-head">
	    			<span class="step-name">第二步：选择模板，填写参数</span>
	    			<span class="step-intro">选择短信模板，然后选择每个参数填写替换的内容。可以预览实发文本和计费条数。70字（含）以内算1条，超过70字按67字每条拆分计算。</span>
	    		</div>
	    		<div class="step-body">
	    			<el-row :gutter="15" style="align-items: stretch;">
	    				<el-col :span="7" class="column">
		    					<el-select style="width:100%;" v-model="tplidx" placeholder="A.选择模板" clearable size="medium">
					                <el-option v-for="(item,idx) in templates" :key="idx" :label="item.name" :value="idx"></el-option>
					            </el-select>
				            	<el-select style="width:100%;" v-model="link_type" placeholder="B.选择链接类型" clearable size="medium">
					                <el-option label="问卷调查" value="a"></el-option>
					                <el-option label="投票选举" value="b"></el-option>
					            </el-select>
					            <el-select style="width:100%;" v-model="link_target" placeholder="C.选择链接目标" clearable size="medium">
					                <el-option v-for="(item,idx) in links" :key="idx" :label="item.title" :value="item.id"></el-option>
					            </el-select>
	    				</el-col>
	    				<el-col :span="8" class="column">
	    						<div class="title">模板原文</div>
	    						<el-input type="textarea" v-model="tplContent" placeholder="选取模板后显示内容" :autosize="{minRows: 4, maxRows:6}" readonly style="width:100%;" size="medium"></el-input>	
	    				</el-col>
	    				<el-col :span="9" class="column">
	    					<div class="title">实发文本。字数：<span class="count-2">{{endding_txt.len}}</span>，消耗短信：<span class="count-2">{{endding_txt.count}}</span></div>
	    					<div class="edding-box" v-html="endding_txt.txt"></div>
	    				</el-col>
	    			</el-row>
		    	</div>
	    	</div>

	    	<div class="step">
	    		<div class="step-head">
	    			<span class="step-name">第三步：确认配置、短信余量，确定发送</span>
	    			<span class="step-intro">审查人数、条数、余量，一切就绪进行发送。</span>
	    		</div>
	    		<div class="step-body">
	    			<div class="oprate-box">
	    				<div class="bottom">
	    					<span>共发送 </span>
				            <span class="count-2" v-html="''+resident_count"></span>
				            <span> 人，每人 </span>
				            <span class="count-2" v-html="endding_txt.count"></span>
				            <span> 条，本次预计消耗 </span>
				            <span class="count-2" v-html="''+resident_count*endding_txt.count"></span>
				            <span> 条，短信余量有 </span>
				            <span class="count-2" v-html="sms_balance"></span>
				            <span>条。</span>
	    				</div>
	    				
			        	<el-button type="primary" @click="click_done">立即发送</el-button>
	    			</div>
	
			   
			            	
	
		    	</div>
	    	</div>
	    	
	        
	    </div>
	</div>
</template>
<script>
import {inject} from 'vue'
export default {
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
        	timeout_handle:'',
        	bufh_setting_list:{},
        	bufh_setting:{},
		    now:Date.now(),
		    area:'',
		    my_estate:[],//来自服务器给出的小区
		    estate:'',
		    bufh:[],
		    residents:[],
		    resident:[],
		    signed:'【安岳物管】',
		    templates:[],
		    tplidx:'',
		    tplContent:'',
		    link_type:'',
		    links:[],
		    link_target:'',
		    param:{value:'', length:0},
		    people_count:0,
		    sms_balance:"？",
		    cascader_config:{
	            expandTrigger:'hover',
	            checkStrictly:true,
	            lazy: true,
	            lazyLoad:(node, resolve)=>{
	                this.axios.post("/api/area/list",{id:node.value || 0}).then(ret=>{
	                    if(!ret.ok){ this.$message.error(ret.msg); return;}
	                    let nodes = [];
	                    ret.data.map(item=>{
	                        nodes.push({value:item.id, label:item.name, leaf:item.hasChildren<1});
	                    });
	                    resolve(nodes);
	                });
	            }
        	},//cascader_config
        	house_config:{
        		lazy:true,
        		value:'id',
        		label:'name',
        		expandTrigger:'hover',
        		checkStrictly:true,
        		lazyLoad:(node, resolve)=>{
			        this.axios.post("/api/house/list",{eid:this.estate, id:node.value || 0}).then(ret=>{
			            if(!ret.ok){this.$message.error(ret.msg); resolve([]); return;}
			            resolve(ret.data);
			        });

			    }
        	},//house_config
        }
    },//data end
    mounted:function(){
        this.init();
    },//mounted end
    computed:{
    	endding_txt:function(){
    		let txt = this.tplContent;
    		if(this.tplContent.includes("{estate_name}")){
    			let ename = '小区名称';
		    	for(let i=0; i<this.gd.estate.length;i++){
		    		if(this.gd.estate[i].id == this.gd.me.eid){ ename = this.gd.estate[i].name; break;}
		    	}
		    	txt = txt.replace("{estate_name}", ename);
    		}
    		if(this.tplContent.includes("{link_para}")){
    			//let val = "";
    			//if(this.link_type != ''){ val = this.link_type; }
    			//if(this.link_target != ''){ val += "?"+ this.link_target; }
    			txt = txt.replace("{link_para}", this.link_para);
    		}
    		txt = this.signed+txt;
    		return {txt:txt, len:txt.length, count:txt.length > 70 ? Math.ceil(txt.length / 67) : 1};
    	},//endding_txt
    	estates:function(){
    		return this.my_estate ? this.my_estate : this.gd.estate;
    	},//estates
    	resident_count:function(){
    		return this.resident.length > 0 ? this.resident.length : this.people_count;
    	},//resident_count
    	link_para:function(){
    		return this.link_target ? this.link_type+"?"+this.link_target : this.link_type;
    	},//link_para
    },
    watch:{
        area:function(){
        	this.estate = '';
        	this.load_people_count();
        },//area end
	    estate:function(){
	    	this.load_people_count();
	    	// if(this.bufh_setting_list[this.estate]){ this.bufh_setting = this.bufh_setting_list[this.estate]; return; }
	    	// clearTimeout(this.timeout_handle);
	    	// this.timeout_handle = setTimeout(()=>{
	    	// 	this.load_bufh_setting();
	    	// },500);
	    },//estate
	    bufh:function(){
	    	this.load_people_count();
	    },//bufh
	    tplidx:function(val){
	    	this.tplContent = '';
	    	this.params = [];
	    	this.param = {value:'', length:0};
	    	this.param_idx = '';
	    	if(val === ''){ return; }
	    	this.tplContent = this.templates[val].content;
	    },//tmpidx end
	    link_type:function(val){
	    	this.links = [];
	    	this.link_target = '';
	    	if(val === ''){ return; }
    		this.axios.post("/api/"+(val=='a'?"question":"vote")+"/simple_list").then(ret=>{
    			if(!ret.ok){ this.$message.error(ret.msg); return; }
    			this.links = ret.data;
    		});
	    },//param_idx
    },
    methods:{
	    init: function(){
	    	this.load_templates();
	    	this.load_people_count();
	    },//init
	    load_templates:function(){
	    	this.axios.post("/api/sms/template").then(ret=>{
	    		if(!ret.ok){this.$message.error(ret.msg); return;}
	            this.templates = ret.data;
	    	});
	    },//load_templates
	    load_people_count:function(){
	    	this.axios.post("/api/sms/people_count",{area:this.area, estate:this.estate, bufh:this.bufh}).then(ret=>{
        		if(!ret.ok){ this.$message.error(ret.msg); return;}
        		this.people_count = ret.data.count;
        		this.my_estate = ret.data.estate;
        		this.residents = ret.data.people;
        		this.sms_balance = ret.data.sms_balance;
        	});
	    },//load_people_count
	    load_bufh_setting:function(){
	    	if(!this.estate){return;}
	        this.axios.post("/api/estate/bufh_get",{eid:this.estate}).then(ret=>{
	            if(!ret.ok){this.$message.error(ret.msg); return;}
	            this.bufh_setting_list[this.estate] = ret.data;
	            this.bufh_setting = ret.data;
	        });

	    },//load_bufh_setting
	    bufh_change:function(val){
	    	this.bufh = val;
	    	this.resident = [];
	    	this.load_people_count();
	    },//bufh_change
	    click_done:function(){
	    	if(isNaN(this.sms_balance)){ this.$message.error("请选择具体小区"); return;}
	    	if(isNaN(this.resident_count) || this.resident_count < 1){ this.$message.error("没有发送目标"); return;}
	    	if(this.tplidx === '' || isNaN(this.tplidx)){ this.$message.error("请选择短信模板"); return;}
	    	let tpl = this.templates[this.tplidx];
	    	if(tpl.content.includes("{link_type}") && this.link_type == ''){ this.$message.error("请选择链接类型"); return; }

	    	if(this.resident_count*this.endding_txt.count > this.sms_balance){ this.$message.error("短信余量不足"); return;}
	    	let ename = '小区名称';
	    	for(let i=0; i<this.gd.estate.length;i++){
	    		if(this.gd.estate[i].id == this.gd.me.eid){ ename = this.gd.estate[i].name; break;}
	    	}
	    	this.axios.post("/api/sms/plan",{
	    		area:this.area,
	    		estate:this.estate,
	    		bufh:this.bufh,
	    		resident:this.resident,
	    		tpl:tpl.id,
	    		value:{estate_name:ename, link_para:this.link_para}
	    	}).then(ret=>{
	    		if(!ret.ok){ this.$message.error(ret.msg); return;}
	    		this.$message.success(ret.msg);
	    		this.reset();
	    	});
	    },//click_done
	    reset:function(){
	    	this.area = '';
	    	this.estate = '';
	    	this.bufh = [];
	    	this.resident = [];
	    	this.tplidx = '';
	    	this.link_type = '';
	    	this.link_target = '';
	    	this.tplContent = '';
	    	this.resident_count = '';
	    	this.sms_balance = "？";
	    },//reset
    },//methods end
};
</script>